import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/public/images/1.webp");
import(/* webpackMode: "eager" */ "/app/public/images/1015 x200w.webp");
import(/* webpackMode: "eager" */ "/app/public/images/1015 x200w2.webp");
import(/* webpackMode: "eager" */ "/app/public/images/22.webp");
import(/* webpackMode: "eager" */ "/app/public/images/AboutUs_Banner.png");
import(/* webpackMode: "eager" */ "/app/public/images/BannerImage.png");
import(/* webpackMode: "eager" */ "/app/public/images/brand-image.png");
import(/* webpackMode: "eager" */ "/app/public/images/desktopImage.jpg");
import(/* webpackMode: "eager" */ "/app/public/images/headPhoneImage.svg");
import(/* webpackMode: "eager" */ "/app/public/images/laptop.svg");
import(/* webpackMode: "eager" */ "/app/public/images/mobileimage.svg");
import(/* webpackMode: "eager" */ "/app/public/images/storelogo.jpg");
import(/* webpackMode: "eager" */ "/app/public/images/tabletImage.svg");
import(/* webpackMode: "eager" */ "/app/public/images/tblogo.png");
import(/* webpackMode: "eager" */ "/app/public/images/tbLogoName.png");
import(/* webpackMode: "eager" */ "/app/public/images/TechBazaar.png");
import(/* webpackMode: "eager" */ "/app/public/images/TV.svg");
import(/* webpackMode: "eager" */ "/app/public/images/ultrcodesLogo.svg");
import(/* webpackMode: "eager" */ "/app/public/images/videoIcon.png");
import(/* webpackMode: "eager" */ "/app/src/components/ScreenLoader.tsx");
import(/* webpackMode: "eager" */ "/app/src/sections/Home/Banner.tsx");
import(/* webpackMode: "eager" */ "/app/src/sections/Home/Brands.tsx");
import(/* webpackMode: "eager" */ "/app/src/sections/Home/FeaturedCategories.tsx");
import(/* webpackMode: "eager" */ "/app/src/sections/Home/MostViewed.tsx");
import(/* webpackMode: "eager" */ "/app/src/sections/Home/RecomendedProducts.tsx");
import(/* webpackMode: "eager" */ "/app/src/sections/Home/TopSellers.tsx")