"use client";

import { ProductCard } from "../../components";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import styles from "../../styles/RecomendedProducts.module.css";

import { Row, Tab, Tabs } from "react-bootstrap";
import Link from "next/link";
import { fetchRecommendedListing } from "../../app/apis/getApis";
import { useQuery } from "@tanstack/react-query";
import ProductCardLoading from "../SpecDetails/ProductCardLoading";

const RecomendedProducts = () => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const [catId, setCatId] = useState("0");
  const categories = [
    { id: "1", name: "Laptops" },

    {
      id: "2",
      name: "Mobile",
    },
    { id: "3", name: "Tablets" },
    { id: "4", name: "TV / Monitors" },

    { id: "5", name: "Desktop Computers" },

    { id: "6", name: "Accessories" },
  ];

  interface obj {
    listing_id: number;
    listing_title: string;
    listed_quantity: number;
    online_price: number;
    online_discount: number;
    created_at: string;
    updated_at: string | null;
    location_id: number;
    item: Object | any;
    effective_price: number;
  }
  const getItems = useQuery({
    queryKey: ["catalogue"],
    queryFn: () => fetchRecommendedListing(30, catId),
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      setData(
        data.data.body.data.map((item: obj) => {
          return {
            listingId: item.listing_id,
            title: item.listing_title,
            quantity: item.listed_quantity,
            sale_price: item.online_price,
            discount: item.online_discount,
            created_at: item.created_at,
            updated_at: null,
            location_id: item.location_id,
            item: item.item,
            effectivePrice: item?.effective_price,
          };
        })
      );
    },
  });

  useEffect(() => {
    getItems.refetch();
  }, [catId]);
  const handleTabChange = (key: string | null) => {
    if (key !== null) {
      setActiveTab(key);
      setCatId(key);
    }
  };

  return (
    <div className={`${styles.recomended_div} `}>
      <div className="main">
        <div className="d-flex justify-content-between p-2 mt-2 align-items-start">
          <h2 className={`${styles.re_header} ms-1 my-0`}>
            Recommended Products
          </h2>
          <Link className={`my-0 ${styles.link} `} href="/products">
            View All
          </Link>
        </div>
        {getItems.isLoading ? (
          <div className="d-flex flex-wrap justify-content-center" style={{columnGap:'10px',rowGap:'10px'}}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <ProductCardLoading />
            ))}
          </div>
        ):
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange}
          className="no-wrap"
        >
          <Tab
            eventKey="0"
            title={
              <p
                className={`${
                  activeTab === "0" ? styles.active_tab : styles.tabs
                } `}
              >
                All Categories
              </p>
            }
          >
            
            <Row  className="mx-1 mx-sm-2 justify-content-center row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-5 ">
              {data?.map((item: any, index: number) => (
                <ProductCard
                  key={index}
                  listingId={item.listingId}
                  price={item.sale_price}
                  recomended={true}
                  item={item?.item}
                  title={item.title}
                  condition={item?.item?.condition_item}
                  discount={item?.discount}
                  isRecommendationCards={true}
                  effectivePrice={item?.effectivePrice}
                />

              ))}
            </Row>
         

          </Tab>

          {categories?.map((category: any, categoryIndex: number) => (
            <Tab
              eventKey={category.id}
              title={
                <p
                  className={`${
                    activeTab === category.id ? styles.active_tab : styles.tabs
                  } `}
                >
                  {category.name}
                </p>
              }
              key={categoryIndex}
            >
            <Row  className="mx-1 mx-sm-2 justify-content-center row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-5 ">
            {data?.map((product: any, productIndex: number) => (
                  <ProductCard
                    key={productIndex}
                    listingId={product.listingId}
                    price={product.sale_price}
                    recomended={true}
                    item={product?.item}
                    title={product.title}
                    condition={product?.item?.condition_item}
                    discount={product?.discount}
                    isRecommendationCards={true}
                    effectivePrice={product?.effectivePrice}
                  />
                ))}
              </Row>
            </Tab>
          ))}
        </Tabs>}

     
        <div className="text-center mt-5 d-lg-none "></div>
      </div>
    </div>
  );
};

export default RecomendedProducts;
